body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Multicalendar css */

.MC-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 6px #A0A1A6;
  padding: 20px;
}

.MC-heading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.MC-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  background-color: #F6F6F6;
  padding: 10px;
  border-radius: 8px;
}

.MC-arrowButton, .MC-headerText, .MC-closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
}

.MC-closeIcon {
  width: 20px;
  height: 20px;
}

.MC-dayHeadersRow, .MC-calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MC-dayHeader {
  width: 14.28%;
  text-align: center;
}

.MC-day {
  width: 14.28%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MC-dayText {
  color: #000;
}

.MC-selectedFromDay, .MC-selectedToDay {
  background-color: #4A90E2;
  border-radius: 50%;
}

.MC-inRangeDay {
  background-color: #B3D4FC;
  border-radius: 50%;
}

.MC-futureDayText {
  color: grey;
}

.MC-okButton {
  background-color: #007BFF;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.MC-disabledButton {
  background-color: #ccc;
  cursor: not-allowed;
}

.MC-modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.MC-modalContent {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  text-align: center;
}

.MC-modalText {
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
}

.MC-modalCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}
